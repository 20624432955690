<template>
    <div class="mt-5">
        <b-row>
            <b-col sm="8" xs="12">
                <form @submit.prevent="changePassword" autocomplete="off" id="changePassword">
                    <b-card>
                        <b-card-body>
                            <div class="row">
                                <div class="col-12 col-md-12 mb-3" ref="passwordCurrentInput">
                                    <label for="CurrentPassword" class="fw-medium mb-2 text-grey-input">{{
                                            $t('title.currentPassword')
                                        }}</label>
                                    <div class="input-group">
                                        <div class="form-floating">
                                            <b-form-input
                                                :type="showCurrentPassword?'text':'password'"
                                                v-model="formFields.current_password"
                                                class="form-control form-input"
                                                id="CurrentPassword"
                                                :class="formErrors.first('current_password') ? `is-invalid form-floating` : `form-floating`"
                                                v-on:input="validateCurrentPassword"
                                                @focus="removeError('current_password')"
                                            />
                                            <svg class="icon icon-check" width="16" height="16">
                                                <use :href="icons+'#icon-check'"></use>
                                            </svg>
                                            <span toggle="#CurrentPassword" class="field-icon toggle-password" ref="currentPassword" v-if="formFields.current_password">
                                            <svg
                                                width="16"
                                                height="16"
                                                class="icon icon-Eye"
                                                @click="showHideCurrentPass()"
                                                v-if="!showCurrentPassword"
                                            >
                                                <use :href="icons+'#icon-Eye'"></use>
                                            </svg>
                                            <svg
                                                width="16"
                                                height="16"
                                                class="icon icon-Eye-off"
                                                @click="showHideCurrentPass()"
                                                v-else
                                            >
                                                <use :href="icons+'#icon-Eye-off'"></use>
                                            </svg>
                                        </span>
                                        </div>
                                        <b-form-invalid-feedback :state="formErrors.has('current_password') ? false : null">
                                            {{ formErrors.first('current_password') }}
                                        </b-form-invalid-feedback>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 mb-3" ref="passwordNewInput">
                                    <label for="newPassword" class="fw-medium mb-2 text-grey-input">{{
                                            $t('title.newPassword')
                                        }}</label>
                                    <div class="input-group">
                                        <div class="form-floating">
                                            <b-form-input
                                            :type="showPassword?'text':'password'"
                                            id="newPassword"
                                            class="form-control form-input"
                                            v-model="formFields.new_password"
                                            v-on:input="validatePassword"
                                            @focus="removeError('new_password')"
                                            :class="formErrors.first('new_password') ? `is-invalid form-floating` : `form-floating`"
                                            />
                                            <svg class="icon icon-check" width="16" height="16">
                                                <use :href="icons+'#icon-check'"></use>
                                            </svg>
                                            <span toggle="#newPassword" class="field-icon toggle-password" ref="newPassword" v-if="formFields.new_password">
                                                <svg
                                                    width="16"
                                                    height="16"
                                                    class="icon icon-Eye"
                                                    @click="showHidePass()"
                                                    v-if="!showPassword"
                                                >
                                                <use :href="icons+'#icon-Eye'"></use>
                                                </svg>
                                                <svg
                                                    width="16"
                                                    height="16"
                                                    class="icon icon-Eye-off"
                                                    @click="showHidePass()"
                                                    v-else
                                                >
                                                    <use :href="icons+'#icon-Eye-off'"></use>
                                                </svg>
                                            </span>
                                        </div>
                                        <b-form-invalid-feedback :state="formErrors.has('new_password') ? false : null">
                                            <p v-for="(error, index) in formErrors.$errors.new_password" :key="index">{{ error }}</p>
                                        </b-form-invalid-feedback>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 mb-3" ref="passwordConfirmInput">
                                    <label for="confirmPassword" class="fw-medium mb-2 text-grey-input">{{
                                            $t('title.confirmPassword')
                                        }}</label>
                                    <div class="input-group">
                                        <div class="form-floating">
                                            <b-form-input
                                                v-model="formFields.new_password_confirmation"
                                                :type="showConfirmPassword?'text':'password'"
                                                class="form-control form-input"
                                                id="confirmPassword"
                                                v-on:input="validatePassword"
                                                @focus="removeError('new_password_confirmation')"
                                                :class="formErrors.first('new_password_confirmation') ? `is-invalid form-floating` : `form-floating`"
                                            />
                                            <svg class="icon icon-check" width="16" height="16">
                                                <use :href="icons+'#icon-check'"></use>
                                            </svg>
                                            <span toggle="#confirmPassword" class="field-icon toggle-password" ref="confirmPassword" v-if="formFields.new_password_confirmation">
                                                <svg
                                                    width="16"
                                                    height="16"
                                                    class="icon icon-Eye"
                                                    @click="showHideConfirmPass()"
                                                    v-if="!showConfirmPassword"
                                                >
                                                    <use :href="icons+'#icon-Eye'"></use>
                                                </svg>
                                                <svg
                                                        width="16"
                                                        height="16"
                                                        class="icon icon-Eye-off"
                                                        @click="showHideConfirmPass()"
                                                        v-else
                                                    >
                                                    <use :href="icons+'#icon-Eye-off'"></use>
                                                </svg>
                                            </span>
                                        </div>
                                        <b-form-invalid-feedback :state="formErrors.has('new_password_confirmation') ? false : null">
                                            {{ formErrors.first('new_password_confirmation') }}
                                        </b-form-invalid-feedback>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12">
                                    <button type="submit" class="btn main-btn" :disabled="isSubmitPassword">
                                        <i class="fa fa-spinner fa-spin" v-if="isSubmitPassword"></i>
                                        {{ $t('title.update') }}
                                    </button>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </form>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Error from '@/Util/Error';
import { request } from '@/Util/Request';
import {mapGetters} from "vuex";
import icons from "@/assets/icons.svg";

const DEFAULT_FORM_STATE = {
    current_password:null,
    new_password:null,
    new_password_confirmation:null,
};

export default {
    name: 'ChangePassword',
    data() {
        return {
            formFields: { ...DEFAULT_FORM_STATE },
            loading: false,
            icons: icons,
            formErrors: new Error({}),
            showCurrentPassword:false,
            showPassword:false,
            showConfirmPassword:false,
            isValidateCurrentPassword : false,
            isValidateConfirmPassword : false,
            isSubmitPassword: false
        };

    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    computed: {
        ...mapGetters(['user']),
    },
    methods:{
        validateCurrentPassword() {
            let validation = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
            if(validation.test(this.formFields.current_password) && this.formFields.current_password) {
                this.checkPassword();
            } else {
                this.isValidateConfirmPassword = false;
            }
        },
        validatePassword() {
            let validation = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
            if((validation.test(this.formFields.new_password)) && (validation.test(this.formFields.new_password_confirmation)) && this.formFields.new_password == this.formFields.new_password_confirmation) {
                this.isValidateConfirmPassword = true;
                if(this.formErrors.$errors.new_password && this.isValidateConfirmPassword) {
                    delete this.formErrors.$errors.new_password;
                }
            } else {
                this.isValidateConfirmPassword = false;
            }
        },
        async checkPassword(){
            try {
                const response  =   await request({
                    method:'POST',
                    url:(this.user && this.user.role == 'admin') ? `/admin/profile/check-password` : `/profile/check-password`,
                    data:{
                        'current_password' : this.formFields.current_password
                    },
                });
                const { data } = response;
                if (data) {
                    this.isValidateCurrentPassword = data.isValidatePassword;
                    if(this.formErrors.$errors.current_password && this.isValidateCurrentPassword) {
                        delete this.formErrors.$errors.current_password;
                    }
                }
            } catch (error) {
                this.isValidateCurrentPassword = false;
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                }

                if (error.request && error.request.status && error.request.status === 429) {
                    this.formErrors = new Error({
                        email: 'unauthentic'
                    });
                }
            }
        },
        async changePassword(){
            try {
                this.isSubmitPassword    =   true;
                let payload     =   this.formFields;
                const response  =   await request({
                    method:'POST',
                    url: (this.user && this.user.role == 'admin') ? `/admin/profile/change-password` : `/profile/change-password`,
                    data:payload,
                });
                const { data } = response;
                if (data) {
                    this.notifySuccessWithMsg(this.$t('title.passwordUpdateSuccessMsg'));
                    this.formFields = { ...DEFAULT_FORM_STATE };
                }
            } catch (error) {
                this.isValidateCurrentPassword = false;
                this.isValidateConfirmPassword = false;
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                }

                if (error.request && error.request.status && error.request.status === 429) {
                    this.formErrors = new Error({
                        email: 'unauthentic'
                    });
                }
            }
            this.isSubmitPassword = false;
        },
        async removeError(key){
            if ( typeof key === `object` ) {
                for (let i = 0; i < key.length; i++) {
                    this.formErrors.remove(key[i]);
                }
            } else {
                this.formErrors.remove(key);
            }
        },
        async showHidePass(){
            if(this.formFields.new_password){
                this.$refs.newPassword.classList.toggle('eye-slash');
                this.showPassword = !this.showPassword
            }

        },
        async showHideCurrentPass(){
            if(this.formFields.current_password) {
                this.$refs.currentPassword.classList.toggle('eye-slash');
                this.showCurrentPassword = !this.showCurrentPassword
            }
        },
        async showHideConfirmPass(){
            if(this.formFields.new_password_confirmation){
                this.$refs.confirmPassword.classList.toggle('eye-slash');
                this.showConfirmPassword = !this.showConfirmPassword
            }
        },
        handleClickOutside(event) {
            if (this.$refs.passwordNewInput && !this.$refs.passwordNewInput.contains(event.target) && this.showPassword == true) {
                this.$refs.newPassword.classList.remove('eye-slash');
                this.showPassword = false;
            }
            if (this.$refs.passwordCurrentInput && !this.$refs.passwordCurrentInput.contains(event.target) && this.showCurrentPassword == true) {
                this.$refs.currentPassword.classList.remove('eye-slash');
                this.showCurrentPassword = false;
            }
            if (this.$refs.passwordConfirmInput && !this.$refs.passwordConfirmInput.contains(event.target) && this.showConfirmPassword == true) {
                this.$refs.confirmPassword.classList.remove('eye-slash');
                this.showConfirmPassword = false;
            }
        },
    },
    watch: {
        'formFields.current_password': function (newPassword) {
            if (newPassword && !newPassword.trim()) {
                this.$refs.currentPassword.classList.remove('eye-slash');
                this.showCurrentPassword = false;
            }
        },
        'formFields.new_password': function (newPassword) {
            if (newPassword && !newPassword.trim()) {
                this.$refs.newPassword.classList.remove('eye-slash');
                this.showPassword = false;
            }
        },
        'formFields.new_password_confirmation': function (newPassword) {
            if (newPassword && !newPassword.trim()) {
                this.$refs.confirmPassword.classList.remove('eye-slash');
                this.showConfirmPassword = false;
            }
        }
    },

};
</script>
