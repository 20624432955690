<template>
    <div class="login w-100 d-flex">
        <section class="login col-12 mx-auto align-self-md-center col-md-7 col-xl-5">
            <div class="half-design row">
                <div class="col-12 form-block p-md-4 p-3">
                    <form action="" @submit.prevent="handleSubmit">
                        <h3 class="mb-3">{{ $t('title.signIn') }}</h3>
                        <div class="input-group mb-2 has-validation">
                            <!-- is-invalid or was-validated -->
                            <div
                                :class="formErrors.first('email') ? `is-invalid form-floating` : `form-floating`"
                            >
                                <input
                                    type="email"
                                    id="email"
                                    class="form-control base-input"
                                    placeholder="name@example.com"
                                    v-model="formFields.email"
                                    @focus="removeError"
                                />
                                <label for="email" class="input-grey fs-14">{{ $t('title.email') }}</label>
                                <svg class="icon icon-alert-triangle" width="16" height="16">
                                    <use :href="icons + '#icon-alert-triangle'"></use>
                                </svg>
                                <svg class="icon icon-check" width="16" height="16">
                                    <use :href="icons + '#icon-check'"></use>
                                </svg>
                            </div>
                            <div class="invalid-feedback m-0" v-if="formErrors.get('email')">
                                <span v-for="(error, index) in formErrors.get('email')" :key="index">{{
                                        error
                                    }}
                                </span>
                            </div>
                        </div>
                        <div class="input-group mb-2">
                            <!-- is-invalid or was-validated -->
                            <div
                                :class="formErrors.first('password') ? `is-invalid form-floating` : `form-floating`"
                            >
                                <input
                                    id="password"
                                    class="form-control base-input"
                                    placeholder="Password"
                                    v-model="formFields.password"
                                    @focus="removeError('password')"
                                    :type="showPassword?'text':'password'"
                                    @input="checkPassword"
                                />
                                <label for="password" class="input-grey fs-14">{{ $t('title.password') }}</label>
                                <svg class="icon icon-alert-triangle" width="16" height="16" v-if="!formFields.password && formErrors.get('password')">
                                    <use :href="icons + '#icon-alert-triangle'"></use>
                                </svg>
                                <svg class="icon icon-check" width="16" height="16">
                                    <use :href="icons + '#icon-check'"></use>
                                </svg>
                                <span toggle="#password" class="field-icon toggle-password" ref="passwords">
                                  <svg class="icon icon-Eye" width="16" height="16"  @click="showHidePass()">
                                    <use :href="icons + '#icon-Eye'"></use>
                                  </svg>
                                  <svg class="icon icon-Eye-off" width="16" height="16" @click="showHidePass()">
                                    <use :href="icons + '#icon-Eye-off'"></use>
                                  </svg>
                                </span>
                            </div>
                            <div class="invalid-feedback m-0" v-if="formErrors.get('password')">
                                <span v-for="(error, index) in formErrors.get('password')" :key="index">{{
                                        error
                                    }}
                                </span>
                            </div>
                        </div>
                        <div class="mb-2 d-flex justify-content-center">
                            <div
                                :class="formErrors.first('captcha') ? `is-invalid form-floating no-border` : `form-floating`"
                            >
                                <vue-recaptcha
                                    :key="$route.path"
                                    ref="recaptcha"
                                    :sitekey="siteKey"
                                    @verify="onCaptchaVerified"
                                    @expired="onCaptchaExpired"
                                    @error="onCaptchaError"
                                />
                                <div class="invalid-feedback m-" v-if="formErrors.get('captcha')">
                                    <span v-for="(error, index) in formErrors.get('captcha')" :key="index">{{
                                            error
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn main-btn d-block fs-20 w-100 py-3" :disabled="loader">
                            {{ $t('title.login') }}
                        </button>
                    </form>
                    <div class="d-flex justify-content-between my-2">
                        <div class="remember-input-group col-auto p-0">
                            <input
                                type="checkbox"
                                id="remember"
                                class="visually-hidden"
                                v-model="formFields.remember"
                            />
                            <label for="remember" class="remember-label d-flex align-items-center fs-14">
                                <span class="remember-check d-flex justify-content-center align-items-center">
                                  <svg width="12" height="8.67">
                                    <use :href="icons + '#icon-Check'"></use>
                                  </svg>
                                </span>
                                {{ $t('title.rememberMe') }}</label
                            >
                        </div>
                        <div class="col-auto ms-auto">
                            <router-link
                                :to="{ name: 'forgotPassword' }"
                                class="reset-pass fs-14 fw-bold text-black"
                            >
                                {{ $t('title.forgotYourPassword') }}
                            </router-link>
                        </div>
                    </div>
                    <div>
                        <p class="title text-center"><span class="py-1 bg-white">{{ $t('title.or') }}</span></p>
                    </div>
                    <div class="social-btn mt-37">
                        <!--                        <button data-title="Apple" class="btn social-btn mb-3 w-100">-->
                        <!--                            <svg width="24" height="24" class="icon me-2">-->
                        <!--                                <use :href="icons + '#icon-Apple'"></use>-->
                        <!--                            </svg>-->
                        <!--                            Continue with Apple-->
                        <!--                        </button-->
                        <!--                        >-->
                        <!--                        <button data-title="Microsoft" class="btn social-btn mb-3 w-100">-->
                        <!--                            <svg width="24" height="24" class="icon me-2">-->
                        <!--                                <use :href="icons + '#icon-Microsoft'"></use>-->
                        <!--                            </svg>-->
                        <!--                            Sign in with Microsoft-->
                        <!--                        </button-->
                        <!--                        >-->
                        <!--                        <button data-title="Google" disabled="disabled" class="btn social-btn mb-3 w-100">-->
                        <!--              <span>-->
                        <!--                <svg width="24" height="24" class="icon">-->
                        <!--                  <use :href="icons + '#icon-Google'"></use>-->
                        <!--                </svg>-->
                        <!--              </span>-->
                        <!--                            Sign in with Google-->
                        <!--                        </button>-->
                        <p class="text-ship-grey text-center mt-37">
                            {{ $t('title.newToInklyze') }}
                            <router-link :to="{name:'registration'}" class="text-main text-decoration-none">
                                {{ $t('title.signUp') }}
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Error from "@/Util/Error";
import icons from '@/assets/icons.svg';
import { mapGetters } from "vuex";
import { request } from "@/Util/Request";
import { getAuthUser, hasAuthUser, setStorage } from "@/Util/auth";
import VueRecaptcha from "vue-recaptcha";

const FORM_FIELDS = {
    email: null,
    password: null,
    captcha: null
};

export default {
    data() {
        return {
            formFields: { ...FORM_FIELDS },
            formErrors: new Error({}),
            icons: icons,
            showPassword: false,
            captchaVerified: false,
            siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
        };
    },
    components: {
        VueRecaptcha,
    },
    created() {
        this.$nextTick(function() {
            if(this.$refs.recaptcha != undefined){
                this.$refs.recaptcha.reset();
            }
        })
    },
    mounted() {
        this.$refs.recaptcha.reset();
        if ( hasAuthUser() ) {
            if ( getAuthUser().role !== 'admin' ) {
                this.$router.push({ name: 'dashboard' });
            } else {
                this.$router.push({ name: 'adminDashboard' });
            }
        }
    },
    methods: {
        onCaptchaVerified(response) {
            this.formFields.captcha = response;
            this.captchaVerified = true; // Enable form submission
            this.removeError('captcha');
        },
        onCaptchaExpired() {
            this.captchaVerified = false; // Disable form submission if CAPTCHA expires
        },
        onCaptchaError() {
            console.log('CAPTCHA error');
        },
        checkPassword() {
            if ( this.formFields.password && !this.formErrors.get('password') || !this.formFields.password ) {
                this.$refs.passwords.style.removeProperty('display');
                this.$refs.passwords.classList.remove('eye-slash');
                this.showPassword = false;
            }
        },
        async showHidePass() {
            if ( this.formFields.password ) {
                this.$refs.passwords.classList.toggle('eye-slash');
                this.showPassword = !this.showPassword;
            }
        },
        async handleSubmit() {
            try {
                const response = await request({
                    method: 'POST',
                    url: `/admin/auth/login`,
                    data: this.formFields,
                });
                const { data } = response;

                await setStorage("auth", JSON.stringify(data));

                await this.$store.dispatch("user", data);

                await this.$router.push({ name: "adminDashboard" });
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                }

                if ( error.request && error.request.status && error.request.status === 429 ) {
                    this.formErrors = new Error({ email: 'unauthentic' });
                }
            }
        },
        removeError() {
            this.formErrors = new Error({});
        }
    },
    computed: {
        ...mapGetters(['loader'])
    },
};
</script>
