<template>
    <div class="report-page" id="userAddEditForm">
        <div class="col-12 text-center py-2" v-if="!$route.params.id && operation=='edit'">
            <i class="fa fa-spin fa-spinner"></i>
        </div>
        <div v-else>
            <div class="container">
                <div class="row d-flex align-items-center mb-36">
                    <div class="info-report d-flex flex-wrap gap-32 col-auto">
                        {{ !formFields.id ? $t('title.add') : $t('title.edit') }} {{ $t('title.user') }}
                    </div>
                    <router-link :to="{name:'adminUser'}" class="sec-color text-decoration-none"
                    >
                        <svg
                            class="me-2"
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM12 3.5L1 3.5L1 4.5L12 4.5L12 3.5Z"
                            />
                        </svg>
                        {{ $t('title.back') }}
                    </router-link>
                </div>
            </div>
            <form @submit.prevent="handleSubmit" autocomplete="off" class="admin-user-form">
                <div class="row mx-0 mb-4">
                    <hr class="col-12 hr-basic mb-0" />
                </div>
                <div class="row col-6 mb-3 mb-lg-5 mb-xxl-60">
                    <div class="col-6 col-xs-12 mb-5">
                        <!--  add class was-validated to the previous line for invalid design -->
                        <label for="name" class="fw-medium mb-2 text-grey-input">{{ $t('title.fullName') }}</label>
                        <input
                            v-model="formFields.name"
                            type="text"
                            class="form-control form-input"
                            id="name"
                            name="name"
                            @focus="removeError('name')"
                        />
                        <p class="text-danger" v-if="formErrors.has('name')">
                            {{ formErrors.first('name') }}
                        </p>
                    </div>
                    <div class="col-6 col-xs-12 mb-5">
                        <label for="email" class="fw-medium mb-2 text-grey-input">{{ $t('title.email') }}</label>
                        <input
                            v-model="formFields.email"
                            type="text"
                            class="form-control form-input"
                            id="email"
                            name="email"
                            @focus="removeError('email')"
                        />
                        <p class="text-danger" v-if="formErrors.has('email')">
                            {{ formErrors.first('email') }}
                        </p>
                    </div>
                    <div class="col-6 col-xs-12 mb-5">
                        <label for="role" class="fw-medium mb-2 text-grey-input">{{ $t('title.role') }}</label>
                        <treeselect v-model="formFields.role" class="form-input" :options="dropdowns.roles" @input="removeError('role')" />
                        <p class="text-danger" v-if="formErrors.has('role')">
                            {{ formErrors.first('role') }}
                        </p>
                    </div>
                    <div class="col-6 col-xs-12 mb-5">
                        <label for="is_active" class="fw-medium mb-2 text-grey-input">{{ $t('title.isLogin') }}</label>
                        <b-form-checkbox v-model="formFields.is_active" id="is_active" />
                    </div>
                    <div class="col-6 col-xs-12 mb-5">
                        <label for="password" class="fw-medium mb-2 text-grey-input">{{ $t('title.password') }}</label>
                        <div class="input-group">
                            <div class="form-floating">
                                <input
                                    id="password"
                                    class="form-control form-input"
                                    v-model="formFields.password"
                                    :type="showPassword?'text':'password'"
                                    :disabled="(formFields.id) ? false : true"
                                    @input="checkPassword"
                                    @focus="removeError('password')"
                                />
                                <span toggle="#password" class="field-icon toggle-password" ref="passwords">
                      <svg class="icon icon-Eye" width="16" height="16" @click="showHidePass()"
                           v-if="!showPassword">
                        <use :href="icons + '#icon-Eye'"></use>
                      </svg>
                      <svg class="icon icon-Eye-off" width="16" height="16" @click="showHidePass()"
                           v-else>
                        <use :href="icons + '#icon-Eye-off'"></use>
                      </svg>
                    </span>
                            </div>
                        </div>
                        <span class="text-danger" v-if="formErrors.has('password')">
                        <p v-for="(error, index) in formErrors.$errors.password" :key="index">{{ error }}</p>
                    </span>
                    </div>
                    <div class="col-6 col-xs-12 mb-5">
                        <button class="btn btn-primary generate-strong-password" @click.prevent="generatePassword">Generate Strong Password</button>
                    </div>
                    <div class="col-6 col-xs-12 mb-5" v-if="formFields.id">
                        <label for="password_confirmation" class="fw-medium mb-2 text-grey-input">
                            {{ $t('title.passwordConfirmation') }}
                        </label>
                        <div class="input-group">
                            <div class="form-floating">
                                <input
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    class="form-control form-input"
                                    v-model="formFields.password_confirmation"
                                    :type="showConfirmPassword?'text':'password'"
                                    :disabled="(formFields.id) ? false : true"
                                    @input="checkConfirmPassword"
                                    @focus="removeError('password_confirmation')"
                                />
                                <span toggle="#password_confirmation" class="field-icon toggle-password" ref="confirmPassword">
                              <svg class="icon icon-Eye" width="16" height="16" @click="showHideConfirmPass()"
                                   v-if="!showConfirmPassword">
                                <use :href="icons + '#icon-Eye'"></use>
                              </svg>
                              <svg class="icon icon-Eye-off" width="16" height="16" @click="showHideConfirmPass()"
                                   v-else>
                                <use :href="icons + '#icon-Eye-off'"></use>
                              </svg>
                            </span>
                            </div>
                        </div>
                        <p class="text-danger" v-if="formErrors.has('password_confirmation')">
                            {{ formErrors.first('password_confirmation') }}
                        </p>
                    </div>
                </div>
                <div class="row mx-0 mb-5 mb-xxl-60">
                    <hr class="col-12 hr-basic mb-0" />
                </div>
                <div
                    class="row mb-5 gap-2"
                >
                    <button :disabled="loader" @click="handleCancel" type="button"
                            class="btn main-btn fw-semibold col col-lg-auto mw-220">
                        <span>{{ $t('title.cancel') }}</span>
                    </button>
                    <button :disabled="loader" type="submit" class="btn main-btn fw-semibold col col-lg-auto mw-220">
                        <span>{{ $t('title.saveAndClose') }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import { request } from "@/Util/Request";
import { mapGetters } from "vuex";
import Error from "@/Util/Error";
import Treeselect from "@riophae/vue-treeselect";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import icons from "@/assets/icons.svg";

const DEFAULT_FORM_STATE = {
    name: null,
    email: null,
    password: null,
    role: null,
    is_active: false,
    password_confirmation: null,
};

export default {
    components: { Treeselect },
    data() {
        return {
            questions: [],
            detail: null,
            operation: null,
            dropdowns: {
                roles: []
            },
            formFields: { ...DEFAULT_FORM_STATE },
            formErrors: new Error({}),
            icons: icons,
            showPassword: false,
            showConfirmPassword: false,
        };
    },
    mounted() {
        this.getDropdowns();
        if ( this.$route.params && this.$route.params.id ) {
            this.getDetail(this.$route.params.id);

            if ( this.$route.params.operation ) {
                this.operation = this.$route.params.operation;
            }
        }
    },
    methods: {
        checkPassword() {
            if ( this.formFields.password && !this.formErrors.get('password') || !this.formFields.password ) {
                this.$refs.passwords.style.removeProperty('display');
                this.$refs.passwords.classList.remove('eye-slash');
                this.showPassword = false;
            }
        },
        async showHidePass() {
            if ( this.formFields.password ) {
                this.$refs.passwords.classList.toggle('eye-slash');
                this.showPassword = !this.showPassword;
            }
        },
        checkConfirmPassword() {
            if ( this.formFields.password_confirmation && !this.formErrors.get('password_confirmation') || !this.formFields.password_confirmation ) {
                this.$refs.confirmPassword.style.removeProperty('display');
                this.$refs.confirmPassword.classList.remove('eye-slash');
                this.showConfirmPassword = false;
            }
        },
        async showHideConfirmPass() {
            if ( this.formFields.password_confirmation ) {
                this.$refs.confirmPassword.classList.toggle('eye-slash');
                this.showConfirmPassword = !this.showConfirmPassword;
            }
        },
        addQuestion() {
            if (this.questions.length > 10) {
                alert('Maximum number of question able to enter only 10');
            } else {
                this.questions.push({});
            }
        },
        generatePassword() {
            const lowerCase = "abcdefghijklmnopqrstuvwxyz";
            const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            const numbers = "0123456789";
            const symbols = "@$!%*#?&";

            const allChars = lowerCase + upperCase + numbers + symbols;

            let password = '';

            // Ensure one character from each required set is included
            password += lowerCase[Math.floor(Math.random() * lowerCase.length)];
            password += upperCase[Math.floor(Math.random() * upperCase.length)];
            password += numbers[Math.floor(Math.random() * numbers.length)];
            password += symbols[Math.floor(Math.random() * symbols.length)];

            // Fill the rest of the password with random characters from all sets
            for (let i = 4; i < 12; i++) {
                password += allChars[Math.floor(Math.random() * allChars.length)];
            }

            // Shuffle the password to avoid predictable patterns
            this.formFields.password  = this.shufflePassword(password);
            this.formFields.password_confirmation = this.formFields.password;
        },
        // Function to shuffle the password to randomize character order
        shufflePassword(password) {
            return password.split('').sort(() => 0.5 - Math.random()).join('');
        },
        async handleSubmit() {
            try {
                if ( this.formFields.id ) {
                    this.formFields._method = 'PUT';
                }

                const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/`;
                this.formFields.url = baseUrl;
                const response = await request({
                    method: 'post',
                    url: this.formFields.id ? `/admin/users/${ this.formFields.id }` : `/admin/users`,
                    data: {
                        ...this.formFields,
                    }
                });

                if ( this.formFields.id ) {
                    this.notifySuccessWithMsg(this.$t('title.user') +' '+ this.$t('title.updateSuccessfully'));
                } else {
                    this.notifySuccessWithMsg(this.$t('title.user') +' '+ this.$t('title.addSuccessfully'));
                }

                this.handleCancel();

            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }
        },

        async getDetail(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/admin/users/${ id }`
                });

                const { data } = response;

                this.formFields.country_id = data.country?.id;
                this.formFields.name = data.name;
                this.formFields.email = data.email;
                this.formFields.id = data.id;
                this.formFields.role = ( _.first(data.roles) || {} ).id;
                this.formFields.is_active = !data.is_new;
                this.formFields.is_active = data.is_active;
                this.histories = _.map(data.histories, (item) => {
                    return {
                        ...item,
                        'starting_date': new Date(item.starting_date),
                        'release_date': new Date(item.release_date),
                    };
                });
                // this.diagnosis = data.diagnosis;

            } catch (error) {
                this.notifyError()
            }
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'get',
                    url: `/admin/dropdowns/roles`
                });

                const { data } = response;
                this.dropdowns.roles = data;

            } catch (error) {
                this.notifyError()
            }
        },
        handleCancel() {
            this.formFields = { ...DEFAULT_FORM_STATE };
            this.$router.push({ name: 'adminUser' });
        },
        removeError(key) {
            if ( typeof key === `object` ) {
                for (let i = 0; i < key.length; i++) {
                    this.formErrors.remove(key[i]);
                }
            } else {
                this.formErrors.remove(key);
            }
        },
    },
    computed: {
        ...mapGetters(['loader'])
    }
};
</script>
