<template>
    <div class="pt-5">
        <b-row>
            <b-col sm="8" xs="12">
                <form @submit.prevent="handleSubmit" autocomplete="off">
                    <b-card>
                        <b-card-body>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <!--  add class was-validated to the previous line for invalid design -->
                                    <label for="name" class="fw-medium mb-2 text-grey-input">{{
                                            $t('title.fullName')
                                        }}</label>
                                    <div class="d-flex">
                                        <b-form-input
                                            v-model="formFields.name"
                                            type="text"
                                            class="form-control form-input mr-20"
                                            id="name"
                                            name="name"
                                            @focus="removeError('name')"
                                            :class="formErrors.first('name') ? `is-invalid form-floating` : `form-floating`"
                                        />
                                        <button type="submit" class="btn main-btn d-block fs-20 w-50" :disabled="isSubmitButton">
                                            <i class="fa fa-spinner fa-spin" v-if="isSubmitButton"></i>
                                            {{ $t('title.update') }}
                                        </button>
                                    </div>
                                    <b-form-invalid-feedback :state="formErrors.has('name') ? false : null">
                                        {{ formErrors.first('name') }}
                                    </b-form-invalid-feedback>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </form>
            </b-col>
        </b-row>
        <change-email/>
        <change-password/>
    </div>
</template>

<script>
import { request } from "@/Util/Request";
import Error from "@/Util/Error";
import { mapGetters } from "vuex";
import changeEmail from "@/views/profile/partials/user/changeEmail";
import ChangePassword from "@/components/ChangePassword";

const FORM_DATA = {
    name: null
};

export default {
    data() {
        return {
            formFields: { ...FORM_DATA },
            formErrors: new Error({}),
            isSubmitButton: false,
        };
    },
    components: {
        changeEmail,
        ChangePassword
    },
    mounted() {
        this.getProfile();
    },
    computed: {
        ...mapGetters(['loader']),
    },
    methods: {
        async getProfile() {
            try {
                const response = await request({
                    method: 'get',
                    url: '/auth/profile?cards=1',
                });

                const { data } = response;
                delete data.monthly_plan;
                delete data.card;
                delete data.wallet;

                this.formFields = data;
                this.email = data.email;

            } catch (error) {
                if ( error.request && error.request.status && error.request.status !== 401 ) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
            }
        },
        removeError(key) {
            if ( typeof key === `object` ) {
                for (let i = 0; i < key.length; i++) {
                    this.formErrors.remove(key[i]);
                }
            } else {
                this.formErrors.remove(key);
            }
        },
        async handleSubmit() {
            this.formErrors = new Error({});
            try {
                this.isSubmitButton = true;
                const response = await request({
                    method: 'post',
                    url: '/auth/profile',
                    data: this.formFields
                });

                this.notifySuccessUpdate(this.$t('title.profile'));
                setTimeout(() => {
                    this.isSubmitButton = false;
                }, 3000);

            } catch (error) {
                this.isSubmitButton = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    if ( error.request && error.request.status && error.request.status !== 401 ) {
                        this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                    }
                }

            }
        },

    }
};
</script>
