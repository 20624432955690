import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { DataTable } from '@andresouzaabreu/vue-data-table';
import '@andresouzaabreu/vue-data-table/dist/DataTable.css';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import './styles/main.scss';
import { calculatePercentageNumberFormat, dateFormat, handleSyncRequestLoader, roundNumberFormat } from '@/Util/auth';
import store from './store';
import _ from 'lodash';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import CrudTable from "@/components/CrudTable";
import { i18n } from './Localization';
import BootstrapVue from 'bootstrap-vue';
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import NotifyMixin from "@/Util/NotifyMixin";
import Vuelidate from 'vuelidate';

Vue.component('data-table', DataTable);
Vue.component('datetime', Datetime);
Vue.component('crud-table', CrudTable);
Vue.component('v-select', vSelect);

Vue.config.productionTip = false;

Vue.use(VueToast, {
    position: 'top-right'
});

Vue.use(BootstrapVue);
Vue.use(BootstrapIconsPlugin);
Vue.use(Vuelidate);

Vue.prototype._ = _;
Vue.prototype.$global = {
    dateFormat: (date, format = 'DD/MM/YYYY') => ( ( date ) ? dateFormat(date, format) : null ),
    dateTimeFormat: (date, format = 'DD/MM/YYYY HH:mm:ss') => ( ( date ) ? dateFormat(date, format) : null ),
    calculatePercentageNumberFormat: (number) => calculatePercentageNumberFormat(number),
    roundNumberFormat: (number, digit) => roundNumberFormat(number, digit),
};
Vue.mixin(NotifyMixin);

new Vue({
    store,
    router,
    i18n,
    render: h => h(App),
    created() {
        handleSyncRequestLoader(store, process.env.VUE_APP_API_URL);
    },
}).$mount('#app');
