<template>
    <div class="report-page">
        <div class="container report-page-block">
            <div class="row d-flex align-items-center mb-36 mobile-responsive-report">
                <div class="col-auto back-button-width">
                    <router-link :to="{name:'test'}" class="sec-color text-decoration-none"
                    >
                        <svg
                            class="me-2"
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM12 3.5L1 3.5L1 4.5L12 4.5L12 3.5Z"
                            />
                        </svg>
                        {{ $t('title.back') }}
                    </router-link>
                </div>
                <div class="info-report d-flex flex-wrap gap-32 col-auto ps-0">
                    <div class="id d-flex gap-2">
                        <div class="title fw-bold text-black">ID number:</div>
                        <div class="value sec-color">{{ detail?.id_number }}</div>
                    </div>
                    <div class="gender d-flex gap-2">
                        <div class="title fw-bold text-black">Gender:</div>
                        <div class="value sec-color">{{ _.capitalize(detail?.gender) }}</div>
                    </div>
                    <div class="gender d-flex gap-2">
                        <div class="title fw-bold text-black">Birthday:</div>
                        <div class="value sec-color">{{ $global.dateFormat(detail?.dob) }}</div>
                    </div>
                </div>

                <div class="col-auto ms-auto">
                    <div class="d-flex gap-2">
                        <button type="button" class="btn main-btn px-32" @click="handleSubmitTestQuestions">Save</button>
                        <button v-if="detail && detail.total_question == 10 && detail.is_report_in_process == 0"
                                     @click.prevent=generateReport(); class="btn main-btn border-0">
                            {{ ( !detail.completed_at ) ? 'Generate report' : 'Report' }}
                        </button>
                        <button v-if="detail && detail.total_question == 10 && detail.is_report_in_process == 1" type="button" class="btn main-btn" disabled="disabled">Report is in-progress</button>
                    </div>
                </div>
            </div>
            <div class="row vertical-tab tab-cards m-0" :class="[{'no-data':!questions.length}]">
                <ul class="nav d-flex flex-column col-auto" id="reportTab" role="tablist">
                    <li class="nav-item" role="presentation" v-for="(question,index) in questions" :key="index"
                        @click="handleTabChange(index)">
                        <button
                            :class="!index ? 'nav-link active' : 'nav-link'"
                            :id="`card-tab-${index+1}`"
                            data-bs-toggle="tab"
                            :data-bs-target="`#${index+1}-card`"
                            type="button"
                            role="tab"
                            :aria-controls="`${index+1}-card`"
                            aria-selected="true"
                        >
                            Card {{ convertToRoman(index + 1) }}
                        </button>
                    </li>
                </ul>
                <div class="tab-content col" id="reportTabContent" ref="reportTabContent">
                    <div
                        class="tab-pane fade"
                        :class="{ 'show active': !index }"
                        :id="`${index+1}-card`"
                        role="tabpanel"
                        :aria-labelledby="`card-tab-${index+1}`"
                        v-for="(cardQuestions, index) in questions" :key="index"
                    >
                        <h4 class="fw-bold mb-2">Card {{ convertToRoman(index + 1) }}</h4>
                        <div v-for="(cardQuestion, questionIndex) in cardQuestions" :key="questionIndex">
                                <div class="d-flex justify-content-end mb-2">
                                     <button class="btn btn-danger" v-if="questionIndex != 0 && operation != 'detail'"  @click="deleteQuestion(index, questionIndex)"><i class="fa fa-trash"></i></button>
                                </div>
                                <div class="mb-4">
                                    <CardGroup :item="cardQuestion" :test-id="detail.id"
                                               :operation="operation" :card-no="index" :question-no="questionIndex" @update-question="updateQuestion" />
                                </div>
                            </div>
                        <div  class="d-flex justify-content-start mt-2">
                            <button class="btn sec-btn" @click="addMoreQuestion(index)" v-if="operation != 'detail'" :disabled="isAddMore || detail.is_report_in_process == 1"><i class="fa fa-plus"></i>Add More</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center py-2" v-if="loader">
                <i class="fa fa-spin fa-spinner"></i>
            </div>
        </div>
        <delete-card-question-modal ref="deleteCardQuestion" @delete-local-question="deleteLocalQuestion" v-on:refresh="getDetail(detail.id)"/>
        <leave-question-save-alert-modal ref="leaveQuestionSaveAlert" @handle-submit-test-question="handleSubmitTestQuestions"/>
    </div>
</template>
<script>
import { request } from "@/Util/Request";
import CardGroup from "@/components/CardGroup";
import { mapActions, mapGetters } from "vuex";
import deleteCardQuestionModal from "./modal/deleteCardQuestionModal"
import Error from "@/Util/Error";
import LeaveQuestionSaveAlertModal from "./modal/leaveQuestionSaveAlertModal";

export default {
    components: { CardGroup, deleteCardQuestionModal, LeaveQuestionSaveAlertModal },
    data() {
        return {
            questions: [],
            card_questions: [],
            detail: null,
            operation: null,
            currentTab: 0,
            formErrors: new Error({}),
            isAddMore: false,
            wallet:{}
        };
    },
    mounted() {
        if ( this.$route.params && this.$route.params.id ) {
            this.getDetail(this.$route.params.id);

            if ( this.$route.params.operation ) {
                this.operation = this.$route.params.operation;
            }
        }

        window.addEventListener('beforeunload', this.handleBeforeUnload);
    },
    beforeDestroy() {
        // Clean up event listener
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
    beforeRouteLeave(to, from, next) {
        // Intercept route change if there are unsaved changes
        this.handleNavigation(next);
    },
    methods: {
        ...mapActions(['setIsQuestionSave', 'setIsQuestionChange']),
        handleNavigation(next) {
            if (this.isQuestionChange) {
                this.$refs['leaveQuestionSaveAlert'].handleToggleModal(); // Show confirmation modal
                next(false); // Prevent immediate navigation
            } else {
                next(); // No unsaved changes, proceed with navigation
            }
        },
        handleBeforeUnload(event) {
            // Show the confirmation dialog on page refresh or closing the tab/window
            if (this.isQuestionChange) {
                event.preventDefault();
                this.$refs['leaveQuestionSaveAlert'].handleToggleModal();
                event.returnValue = ''; // For most browsers
                return ''; // For some older browsers
            }
        },
        updateQuestion(cardIndex, questionIndex, updatedQuestion) {
            this.questions[cardIndex].splice(questionIndex, 1, updatedQuestion);
        },
        addMoreQuestion(cardIndex) {
            this.questions[cardIndex].push({
                id: null,
                free_association_phase: '',
                inquiry_phase: '',
                card_no: cardIndex
            });
            setTimeout(() => {
                this.cardToScrollBottom();
            }, 500);
        },
        deleteQuestion(cardIndex, questionIndex) {
            let questionId = this.questions[cardIndex][questionIndex].id;
            this.$refs['deleteCardQuestion'].handleToggleModal(questionId, cardIndex, questionIndex);
        },
        deleteLocalQuestion(cardIndex, questionIndex) {
            this.questions[cardIndex].splice(questionIndex, 1);
        },
        handleTabChange(index) {
            this.currentTab = index;
            const scrollableDiv = this.$refs.reportTabContent;
            if ( scrollableDiv ) {
                scrollableDiv.scrollTop = 0; // Scroll the specific div to the top
            }
        },
        cardToScrollBottom() {
            const scrollableDiv = this.$refs.reportTabContent;
            scrollableDiv.scrollTo({
                top: scrollableDiv.scrollHeight,
                behavior: 'smooth', // Enable smooth scrolling
            });
        },
        async getDetail(id) {
            try {
                this.setIsQuestionSave(true);
                const response = await request({
                    method: 'get',
                    url: `/tests/${ id }`
                });

                const { data } = response;

                this.detail = _.pick(data, ['id', 'id_number', 'gender', 'dob', 'completed_at', 'total_question', 'is_report_in_process']);

                this.questions = data.questions;

                if (this.questions.length == 0 && this.operation != 'detail' ) {
                    this.questions = Array.from({ length: 10 }, (_, index) => [{
                        id: null,
                        free_association_phase: '',
                        inquiry_phase: '',
                        card_no: index
                    }])
                }
                this.setIsQuestionSave(false);
            } catch (error) {
                if ( error.request && error.request.status && error.request.status !== 401 ) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
                this.setIsQuestionSave(false);
            } finally{
                this.getWalletData();
            }
        },
        async handleSubmitTestQuestions() {
            try {
                this.setIsQuestionSave(false);
                let testId = this.detail.id;
                const response = await request({
                    method: 'post',
                    url: `/test-questions/${ testId }` ,
                    data: {
                       'questions':this.questions,
                        '_method' : 'PUT'
                    }
                });

                if(response.data) {
                    this.notifySuccessWithMsg(this.$t('title.questionSubmittedSuccess'));
                    this.setIsQuestionSave(false);
                    this.setIsQuestionChange(false);
                    this.getDetail(testId);
                }
            } catch (error) {
                if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }

                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
                this.setIsQuestionSave(false);
                this.setIsQuestionChange(false);
            }
        },
        convertToRoman(num) {
            const romanNumerals = [
                { value: 1000, numeral: 'M' },
                { value: 900, numeral: 'CM' },
                { value: 500, numeral: 'D' },
                { value: 400, numeral: 'CD' },
                { value: 100, numeral: 'C' },
                { value: 90, numeral: 'XC' },
                { value: 50, numeral: 'L' },
                { value: 40, numeral: 'XL' },
                { value: 10, numeral: 'X' },
                { value: 9, numeral: 'IX' },
                { value: 5, numeral: 'V' },
                { value: 4, numeral: 'IV' },
                { value: 1, numeral: 'I' },
            ];

            let result = '';
            for (const { value, numeral } of romanNumerals) {
                while (num >= value) {
                    result += numeral;
                    num -= value;
                }
            }
            return result;
        },
        generateReport(){
            if(this.wallet.credit > 0 || this.detail.completed_at){
                this.$router.push({name:'testReport',params:{id:this.detail?.id}});
            }else{
                this.notifyWarningWithMsg("You don't have enough credits!");
            }
        },
        async getWalletData(){
            try {
                const response = await request({
                    method: 'get',
                    url: '/auth/user-wallet',
                });
                const { data } = response;
                this.wallet = data;
            } catch (error) {
                if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
            }
        }
    },
    computed: {
        ...mapGetters(['loader', 'isQuestionChange'])
    }
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
    .mobile-responsive-report {
        margin-bottom: 20px !important;
    }
}
</style>
