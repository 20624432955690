<template>
    <div class="credit-card">
        <div class="btn-group" v-if="!subscription">
            <b-button variant="primary"
                      v-if=" !subscription || (expiredSubscription && expiredSubscription.is_active == 0)"
                      @click="openModal" :disabled="(currentMonthSubscription != null || loader) ? true : false"
                      size="sm">
                <i class="fa fa-spinner fa-spin mr-4" v-if="isSubscribedPlan"></i>
                <i class="bi bi-basket" />
                {{ $t('title.subscribed') }} {{ $t('title.plan') }}
            </b-button>
        </div>
        <div class="btn-group" v-if="subscription && subscription.plan_id == plan.id">
            <b-button variant="warning" v-if="subscription.is_active == 1" @click="openStatusConfirmationModal"
                      size="sm">
                <i class="bi bi-basket" />
                {{ $t('title.unsubscribed') }}
            </b-button>

            <b-button variant="success" v-else @click="openStatusConfirmationModal" size="sm">
                <i class="bi bi-basket" />
                {{ $t('title.subscribed') }}
            </b-button>

            <button variant="primary" v-if="subscription && cardDetail" class="ms-1" size="sm" @click="editCardModal">
                <i class="bi bi-credit-card" />
                {{ $t('title.edit') }} {{ $t('title.card') }}
            </button>
        </div>
        <div class="btn-group" v-else></div>

        <b-modal id="card-modal" title="Card Information" @ok="handleSubmit" @close="closeCreditCardModal" size="lg"
                 :hide-footer="true"
                 :visible="showModal" :no-close-on-esc="true" :no-close-on-backdrop="true"
        >
            <b-form @submit.stop.prevent="handleSubmit" novalidate>
                <div class="row">
                    <div class="d-flex flex-column mb-3">
                        <label for="cardNumber"
                               class="text-secondary form-label mb-3">{{ this.$t('title.creditCardNumber') }}</label>
                        <div :class="formErrors.first('card_no')?`input-group is-invalid`:`input-group`">
                            <input
                                type="text"
                                id="cardNumber"
                                class="form-control input-grey fs-14 default-input mini-input"
                                v-model="formFields.card_no"
                                @input="validateCardNo"
                                @focus="removeError('card_no')"
                            />
                            <span class="payment-card">
                                <svg width="35" height="24" class="icon icon-Mastercard">
                                    <use :href="icons + '#icon-Mastercard'"></use>
                                </svg>
                                <svg width="35" height="24" class="icon icon-Visa">
                                    <use :href="icons + '#icon-Visa'"></use>
                                </svg>
                                <svg width="35" height="24" class="icon icon-DinersClub">
                                    <use :href="icons + '#icon-DinersClub'"></use>
                                </svg>
                            </span>
                            <svg width="16" height="16" class="icon icon-alert-triangle">
                                <use :href="icons + '#icon-alert-triangle'"></use>
                            </svg>
                            <svg width="16" height="16" class="icon icon-check">
                                <i class="bi bi-card-checklist" />
                            </svg>
                        </div>
                        <div class="invalid-feedback" v-if="formErrors.first('card_no')">
                            <span v-for="(error, index) in formErrors.get('card_no')" :key="index">
                                {{ error }}
                            </span>
                        </div>
                    </div>
                    <div class="col-6 mx-0 mb-3">
                        <label for="expiryMonthYear"
                               class="text-secondary form-label mb-3">{{ this.$t('title.expiration') }}</label>
                        <div
                            :class="formErrors.first('expiry_month')?`input-group is-invalid`:formErrors.first('expiry_year')?`input-group is-invalid`:formErrors.first('expiry')?`input-group is-invalid`:`input-group`">
                            <input type="text" id="expiryMonthYear"
                                   class="form-control input-grey fs-14 default-input mini-input"
                                   placeholder="MM/YY"
                                   v-model="expiryMonthYear"
                                   @input="validateExpiry"
                                   @paste="validateExpiry"
                                   @focus="removeError(['expiry_month','expiry_year', 'expiry'])"
                            />
                            <svg width="16" height="16" class="icon icon-alert-triangle">
                                <use :href="icons + '#icon-alert-triangle'"></use>
                            </svg>
                            <svg width="16" height="16" class="icon icon-check">
                                <i class="bi bi-card-checklist" />
                            </svg>
                        </div>
                        <div class="invalid-feedback" v-if="formErrors.first('expiry_month')">
                            <span v-for="(error, index) in formErrors.get('expiry_month')" :key="index">
                                {{ error }}
                            </span>
                        </div>
                        <div class="invalid-feedback" v-if="formErrors.first('expiry_year')">
                            <span v-for="(error, index) in formErrors.get('expiry_year')" :key="index">
                                {{ error }}
                            </span>
                        </div>
                        <div class="invalid-feedback" v-if="formErrors.first('expiry')">
                            <span v-for="(error, index) in formErrors.get('expiry')" :key="index">
                                {{ error }}
                            </span>
                        </div>
                    </div>
                    <div class="col-6 mx-0 mb-3">
                        <label for="cvv" class="text-secondary form-label mb-3">CVC</label>
                        <div :class="formErrors.first('cvv')?`input-group is-invalid`:`input-group`">
                            <!-- ADD HERE CLASS WAS-VALIDATED OR IS-INVALID  -->
                            <input type="password"
                                   id="cvv"
                                   class="form-control input-grey fs-14 default-input mini-input"
                                   placeholder="CVC"
                                   v-model="formFields.cvv"
                                   @input="validateCVV"
                                   @focus="removeError('cvv')"
                            />
                            <svg width="16" height="16" class="icon icon-CVC">
                                <use :href="icons + '#icon-CVC'"></use>
                            </svg>
                        </div>
                        <div class="invalid-feedback" v-if="formErrors.first('cvv')">
                                    <span v-for="(error, index) in formErrors.get('cvv')" :key="index">{{
                                            error
                                        }}</span>
                        </div>
                    </div>
                    <div class="col-6 mx-0 mb-3">
                        <label for="cardHolderName"
                               class="text-secondary form-label mb-3">{{ this.$t('title.holdersName') }}</label>
                        <div
                            :class="formErrors.first('card_holder_name')?`input-group is-invalid`:`input-group`">
                            <input type="text" id="cardHolderName"
                                   class="form-control input-grey fs-14 default-input mini-input"
                                   v-model="formFields.card_holder_name"
                                   @keypress="inputCardHolderName"
                                   @paste.prevent
                                   @focus="removeError('card_holder_name')"
                            />
                            <svg width="16" height="16" class="icon icon-alert-triangle">
                                <use :href="icons + '#icon-alert-triangle'"></use>
                            </svg>
                            <svg width="16" height="16" class="icon icon-check">
                                <i class="bi bi-card-checklist" />
                            </svg>
                        </div>
                        <div class="invalid-feedback" v-if="formErrors.first('card_holder_name')">
                                    <span v-for="(error, index) in formErrors.get('card_holder_name')"
                                          :key="index">{{
                                            error
                                        }}</span>
                        </div>
                    </div>
                    <div class="col-6 mx-0 mb-3">
                        <label for="country"
                               class="text-secondary  form-label mb-3">{{ this.$t('title.country') }}</label>
                        <div>
                            <treeselect :multiple="false"
                                        class="text-input-color fs-14 default-input mini-input"
                                        :class="[{ 'custom-arrow-icon': true },{ 'is-invalid': formErrors.first('country_id')}]"
                                        v-model="formFields.country_id"
                                        id="country"
                                        :options="dropdowns.countries"
                                        @input="removeError('country_id')"
                                        :disableFuzzyMatching="true"></treeselect>
                            <div class="invalid-feedback" v-if="formErrors.first('country_id')">
                            <span v-for="(error, index) in formErrors.get('country_id')" :key="index">
                                {{ error }}
                            </span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="col-12 d-flex justify-content-end gap-4 mb-3">
                        <button type="button" class="btn main-btn d-block fs-20 px-3 col sec-btn h-44" id="cancelBtn"
                                @click="closeCreditCardModal">
                            {{ this.$t('title.cancel') }}
                        </button>
                        <button :disabled="loader" class="btn main-btn col fs-20 h-44 save-button" id="saveBtn"
                                type="submit">
                            <i class="fa fa-spinner fa-spin" v-if="loader"></i>
                            {{ this.$t('title.save') }}
                        </button>
                    </div>
                </div>
            </b-form>
        </b-modal>
        <plan-status-update-confirmation-modal @reload="reload" v-if="subscription" :subscription="subscription"
                                               ref="planStatusUpdateModal" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Error from "@/Util/Error";
import icons from '@/assets/icons.svg';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { request } from "@/Util/Request";
import PlanStatusUpdateConfirmationModal from './modal/planStatusUpdateConfirmationModal';

const DEFAULT_FORM_STATE = {
    country_id: null,
    card_holder_name: null,
    card_no: null,
    expiry_month: null,
    expiry_year: null,
    cvv: null
};

export default {
    props: {
        'subscription': {
            required: true
        },
        'plan': {
            required: true
        },
        'cardDetail': {
            required: false,
            default() {
                return null;
            }
        },
        'currentMonthSubscription': {
            required: true
        },
        'expiredSubscription': {
            required: true
        }
    },
    components: { Treeselect, PlanStatusUpdateConfirmationModal },
    data() {
        return {
            icons: icons,
            showModal: false,
            formFields: { ...DEFAULT_FORM_STATE },
            formErrors: new Error({}),
            dropdowns: {
                countries: [],
            },
            isSubscribedPlan: false,
        };
    },
    mounted() {
        if ( _.isEmpty(this.cardDetail) ) {
            this.formFields.card = 1;
        }

        this.getDropdowns();
        window.addEventListener("keydown", (event) => {
            if ( document.activeElement.className == 'vue-treeselect__input' && event.key === 'Tab' && !event.shiftKey ) {
                document.getElementById('cancelBtn').classList.add('active');
            }

            if ( document.activeElement == document.getElementById('cancelBtn') && event.key === 'Tab' && !event.shiftKey ) {
                document.getElementById('cancelBtn').classList.remove('active');
                document.getElementById('saveBtn').classList.add('active');
            }

            if ( document.activeElement == document.getElementById('saveBtn') && event.key === 'Tab' && !event.shiftKey ) {
                document.getElementById('saveBtn').classList.remove('active');
            }
        });
    },
    methods: {
        inputCardHolderName(evt) {
            evt = evt || window.event;
            const charCode = evt.which ? evt.which : evt.keyCode;
            const value = evt.target.value;

            if ( value.length >= 26 ) {
                evt.preventDefault();
            } else {
                this.formFields.card_holder_name = `${ value.toString().slice(0, 25) }`;
                return true;
            }
        },
        closeCreditCardModal() {
            this.showModal = false;
            this.formErrors = new Error({});
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'get',
                    url: `/dropdowns/countries`
                });

                const { data } = response;
                this.dropdowns.countries = data;
            } catch (error) {
                if ( error.request && error.request.status && error.request.status !== 401 ) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
            }
        },
        async handleSubmit() {

            if ( !this.plan ) {
                this.notifyErrorWithMsg('plan selection required');
            }

            try {
                this.isSubscribedPlan = true;
                if ( this.formFields.card == 'edit' ) {
                    const response = await request({
                        method: 'post',
                        url: `/credit-card/${ this.cardDetail.id }`,
                        data: {
                            ...this.formFields,
                            currency: _.get(this.plan, ['currency', 'code']),
                            amount: ( this.plan || {} ).amount,
                            plan_id: ( this.plan || {} ).id
                        }
                    });

                } else {
                    const response = await request({
                        method: 'post',
                        url: `/subscriptions`,
                        data: {
                            ...this.formFields,
                            currency: _.get(this.plan, ['currency', 'code']),
                            amount: ( this.plan || {} ).amount,
                            plan_id: ( this.plan || {} ).id
                        }
                    });
                }


                this.showModal = false;

                if ( this.formFields.card == 'edit' ) {
                    this.notifySuccessWithMsg(this.$t('title.cardDetailSuccessMsg'));
                } else {
                    this.notifySuccessWithMsg(this.$t('title.subscriptionSuccessMsg'));
                }
                this.$emit('reload');

                delete this.formFields.card;
                this.isSubscribedPlan = false;

            } catch (error) {
                this.isSubscribedPlan = false;
                if ( error.data && error.data.error && error.request.status == 400 ) {
                    this.notifyErrorWithMsg(error.data.error);
                }

                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }
        },
        validateExpiry(event) {
            const cursorPosition = event.target.selectionStart;
            const isDelete = ( event.inputType === "deleteContentForward" || event.inputType === "deleteContentBackward" );
            const newCursortPosition = isDelete ? cursorPosition : cursorPosition - 1;

            const value = event.target.value.replace(/[^0-9]/g, '');
            if ( value.length >= 3 ) {
                event.target.value = `${ value.toString().slice(0, 2) }/${ value.toString().slice(2, 4) }`;
            } else {
                event.target.value = value;
            }
            if ( isDelete ) {
                event.target.setSelectionRange(newCursortPosition, newCursortPosition);
            }
        },
        validateCVV(event) {
            const value = event.target.value.replace(/[^0-9]/g, '');
            if ( value.length > 0 && value.length <= 3 ) {
                this.formFields.cvv = value;
            } else {
                this.formFields.cvv = `${ value.toString().slice(0, 3) }`;

            }
        },
        validateCardNo(event) {
            let charCode = event.which ? event.which : event.keyCode;
            if (
                ( charCode > 31 && ( charCode < 48 || charCode > 57 ) && charCode !== 46 ) ||
                event.target.value + String.fromCharCode(charCode) > 60
            ) {
                event.preventDefault();
            } else {
                const value = event.target.value.replace(/[^0-9]/g, '');
                this.formFields.card_no = `${ value.toString().slice(0, 16) }`;
                return true;
            }
        },
        removeError(key) {
            if ( typeof key === `object` ) {
                for (let i = 0; i < key.length; i++) {
                    this.formErrors.remove(key[i]);
                }
            } else {
                this.formErrors.remove(key);
            }
        },
        openModal() {
            if ( _.isEmpty(this.cardDetail) ) {
                this.showModal = true;
            } else {
                this.handleSubmit();
            }
        },
        editCardModal() {
            this.formFields = { ...this.cardDetail };
            this.formFields.card = 'edit';
            this.showModal = true;
        },
        openStatusConfirmationModal() {
            this.$refs.planStatusUpdateModal.handleToggleModal();
        },
        reload() {
            this.$emit('reload');
        }
    },
    computed: {
        ...mapGetters(['loader']),
        countryId: {
            get() {
                return this.dropdowns.countries.find(option => option.id === this.formFields.country_id) ?? null;
            },
            set(value) {
                this.formFields.country_id = value ? value.id : null;
            }
        },
        expiryMonthYear: {
            get() {
                let monthYear = null;
                if ( this.formFields.expiry_month ) {
                    monthYear = this.formFields.expiry_month;
                    if ( monthYear > 1 && monthYear <= 9 && monthYear.toString().length == 1 ) {
                        monthYear = `0${ this.formFields.expiry_month }`;
                    } else {
                        monthYear = this.formFields.expiry_month;
                    }
                }
                if ( this.formFields.expiry_year ) {
                    monthYear += '/' + this.formFields.expiry_year;
                }
                return monthYear;
            },
            set(value) {
                const currentYear = new Date().getFullYear().toString();
                const currentMonth = new Date().getMonth().toString();
                const actualVal = value.replace(/\D/g, '');
                const month = `${ actualVal.slice(0, 2) }`;
                const year = `${ actualVal.slice(2, 4) }`;
                if ( month >= 1 && month <= 12 ) {
                    if ( month > 1 && month <= 9 && month.length == 1 ) {
                        this.formFields.expiry_month = `0${ month }`;
                    } else {
                        this.formFields.expiry_month = month;
                    }
                } else {
                    if ( month > 12 ) {
                        this.formFields.expiry_month = 12;
                    } else {
                        this.formFields.expiry_month = month;
                    }
                }

                this.formFields.expiry_year = year;

                if ( year >= currentYear.slice(2, 4) || ( year == currentYear.slice(2, 4) && month >= parseInt(currentMonth) ) ) {
                    this.formFields.expiry_year = year;
                }

                if ( year < 1 ) {
                    this.formFields.expiry_year = '';
                }
            }
        }
    },
    watch: {
        'cardDetail': function (newVal) {
            if ( !_.isEmpty(newVal) ) {
                this.formFields = {
                    ...newVal
                };
            } else {
                this.formFields.card = 1;
            }
        }
    }
};
</script>

<style>
.save-button.active {
    background-color: var(--sec-color) !important;
}

.credit-card .mr-4 {
    margin-right: 2px;
}
</style>
