<template>
    <div>
        <div class="col-12 text-center py-2" v-if="isQuestionSave">
            <i class="fa fa-spin fa-spinner"></i>
        </div>
        <div>
            <div class="row d-flex align-items-center mb-2">
                <div class="fw-bold col-auto">Free Association Phase <span class="custom-badge">{{questionNo + 1}}</span></div>
            </div>
            <div class="row">
                <div class="col-12 mb-4">
                    <div v-if="operation == 'detail'" v-html="formFields.free_association_phase"></div>
                    <ckeditor
                        v-if="operation !== 'detail'"
                        :editor="editor"
                        v-model="formFields.free_association_phase"
                        :config="freeAssociationPhaseEditorConfig"
                        :height="height ? height : 330"
                        class="form-control"
                        :id="cardNo"
                    ></ckeditor>
                </div>
            </div>
        </div>
        <div>
            <div class="row d-flex align-items-center mb-2">
                <div class="fw-bold col-auto">Inquiry Phase <span class="custom-badge">{{questionNo + 1}}</span></div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div v-if="operation == 'detail'" v-html="formFields.inquiry_phase"></div>
                    <ckeditor
                        v-if="operation !== 'detail'"
                        :editor="editor"
                        v-model="formFields.inquiry_phase"
                        :config="inquiryPhaseEditorConfig"
                        :height="height ? height : 330"
                        class="form-control"
                        :id="cardNo"
                    ></ckeditor>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import {mapActions, mapGetters} from "vuex";

const FORM_STATE = {
    id: null,
    free_association_phase: '',
    inquiry_phase: '',
    card_no: 0
};

export default {
    props: ['showMenubar', 'height', 'item', 'testId', 'operation', 'title', 'field', 'cardNo', 'questionNo'],
    components: {
        ckeditor: CKEditor.component,
    },
    data() {
        return {
            editor: ClassicEditor,
            editorData: '',
            freeAssociationPhaseEditorConfig: {
                placeholder: '',
                toolbar: {
                    items: [],
                },
            },
            inquiryPhaseEditorConfig: {
                placeholder: '',
                toolbar: {
                    items: [],
                },
            },
            formFields: { ...FORM_STATE },
            oldFormFields: { ...FORM_STATE },
        };
    },
    mounted() {
        if ( !_.isEmpty(this.item) ) {
            this.formFields = { ...this.item };
            this.formFields.inquiry_phase = (this.formFields.inquiry_phase) ? this.formFields.inquiry_phase  : '';
            this.formFields.free_association_phase = (this.formFields.free_association_phase) ? this.formFields.free_association_phase  : '';

            //old form fields
            this.oldFormFields = { ...this.item };
            this.oldFormFields.inquiry_phase = (this.oldFormFields.inquiry_phase) ? this.oldFormFields.inquiry_phase  : '';
            this.oldFormFields.free_association_phase = (this.oldFormFields.free_association_phase) ? this.oldFormFields.free_association_phase  : '';
        }
        this.setIsQuestionSave(false);
    },
    methods: {
        ...mapActions(['setIsQuestionSave', 'setIsQuestionChange']),
        deepCompareObjects(obj1, obj2) {
            if ( !_.isEmpty(obj1) && !_.isEmpty(obj2) ) {
                return JSON.stringify(obj1) === JSON.stringify(obj2);
            }
            return true;
        },
    },
    watch: {
        'formFields': {
            handler(newQuestion) {
                this.$emit('update-question', this.cardNo, this.questionNo, newQuestion);
                this.setIsQuestionChange(!this.isFillable);
            },
            deep: true
        },
    },
    computed: {
        ...mapGetters(['isQuestionSave', 'isQuestionChange']),
        isFillable() {
            return this.deepCompareObjects(this.formFields, this.oldFormFields);
        },
    }
};
</script>
