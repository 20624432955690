<template>
    <div>
        <div class="card">
            <div class="card-header">
                <h5>
                    {{ $t('title.users') }}
                </h5>
            </div>
            <div class="card-body">
                <crud-table :columns="columns" :list-url="listUrl" ref="user-table">
                    <template v-slot:default>
                        <router-link :to="{'name':'adminUserForm',params:{operation:'create'}}" :disable="loader"
                                     class="btn main-btn gap-1 me-2" title="Add New">
                                <span class="d-flex justify-content-center align-items-center gap-1">
                                    {{ $t('title.addNewUser') }} <i class="bi bi-plus-lg"></i>
                               </span>
                        </router-link>
                    </template>
                    <template #cell(is_active)="{item}">
                        <span v-if="item.is_active" :class="`badge bg-success text-white`"> {{ $t('title.yes') }}</span>
                        <span v-else :class="`badge bg-danger text-white`"> {{ $t('title.no') }}</span>
                    </template>
                    <template #cell(is_new)="{item}">
                        <span v-if="item.is_new" :class="`badge bg-danger text-white`"> {{ $t('title.no') }}</span>
                        <span v-else :class="`badge bg-success text-white`"> {{ $t('title.yes') }}</span>
                    </template>
                    <template #cell(action)="{item}">
                        <router-link :to="{'name':'adminUserForm',params:{id:item.id,operation:'edit'}}"
                                     @click="operation = 'edit'" v-if="user?.email !== item.email">
                            <i class="bi bi-pencil-fill" />
                        </router-link>
                        <a href="javascript:;" @click.prevent="handleDelete(item.id)" class="ms-2"
                           v-if="user?.email !== item.email">
                            <i class="bi bi-trash-fill"></i>
                        </a>
                    </template>
                </crud-table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            operation: null,
            listUrl: '/admin/users',
            columns: [
                {
                    key: 'email',
                    label: this.$t('title.email'),
                    sortable: true
                },
                {
                    key: 'is_active',
                    label: this.$t('title.isActive'),
                    sortable: false
                },
                {
                    key: 'is_new',
                    label: this.$t('title.isVerified'),
                    sortable: true
                },
                {
                    key: 'action',
                    label: this.$t('title.action'),
                    sortable: false
                },
            ],
            addTestModal: false
        };
    },
    methods: {
        handleOpenClick() {
            this.$refs['addTest'].handleToggleModal();
        },
        async handleDelete(id) {
            this.$refs['user-table'].handleDeleteShow(`/admin/users/${ id }`, 'User');
        },
        refreshList() {
            this.$refs['user-table'].refreshTableData();
        }
    },
    computed: {
        ...mapGetters(['loader', 'user'])
    }
};
</script>
