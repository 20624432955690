<!--do not modify this file until not required-->
<template>
    <div class="row">
        <div class="col-12 text-center py-2" v-if="loader">
            <i class="fa fa-spin fa-spinner"></i>
        </div>
        <div class="col-12 mb-3">
            <div class="position-relative input-search">
                <b-form-input :disable="loader" v-model="query.search" :placeholder="`${$t('title.enterToSearch')}`"
                              id="filter-input"
                              class="w-100 form-control default-input"
                              @keyup.enter="handleSearch" />
                <span class="icon-inside">
                    <a :disabled="loader" @click="handleSearch" title="search">
                        <i class="fa fa-search"></i>
                    </a>
                </span>
            </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-4 w-25">
                <v-select
                    :disable="loader"
                    :options="pageSelection"
                    label="name"
                    class="default-select w-100"
                    id="perPage"
                    v-model="query.perPage"
                    :searchable="false"
                />
            </div>
        </div>
        <div class="col-6">
            <div class="d-flex gap-2 justify-content-end">
                <!-- slot for add new button-->
                <slot></slot>
                <!-- slot for filter button-->
                <slot name="filter-slot"></slot>
                <button :disabled="loader"
                        class="btn btn-primary" title="refresh" @click="refreshTableData">
                    <i class="bi bi-bootstrap-reboot"></i>
                </button>
            </div>
        </div>
        <div class="col-md-12">
            <div class="table-responsive">
                <b-table
                    responsive
                    :items="dataSources"
                    :fields="columns"
                    v-if="columns.length"
                    label-sort-asc="↑"
                    label-sort-desc="↓"
                    label-sort-clear=""
                    show-empty
                    empty-text="No Result Found..."
                    stacked="md"
                    class="default-design"
                >
                    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
                    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
                        <slot :name="slot" v-bind="scope"></slot>
                    </template>
                </b-table>
                <div class="row d-flex align-items-center gap-3 mt-4 mx-0">
                    <div class="col pe-0">
                        <b-pagination
                            v-if="pagination"
                            v-model="query.page"
                            :total-rows="pagination.total"
                            :per-page="query.perPage"
                            prev-class="previous-arrow"
                            next-class="next-arrow"
                            first-number
                            last-number
                            align="right"
                            class="default-design-pagination"
                            size="sm"
                        ></b-pagination>
                    </div>
                </div>
            </div>
        </div>
        <delete-test-modal ref="deleteTestModal" @handleRefreshList="handleRefreshList"/>
        <delete-user-modal ref="deleteUserModal" @handleRefreshList="handleRefreshList"/>
    </div>
</template>

<script>
import ListingMixin from "@/Util/ListingMixin";
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { request } from "@/Util/Request";
import {mapGetters} from "vuex";
import deleteTestModal from "@/views/admin/test/modal/deleteTestModal";
import deleteUserModal from "@/views/admin/users/modal/deleteUserModal";

export default {
    mixins: [ListingMixin],
    data() {
        return {
            modal: null,
            deleteUrl: null,
            deleteModal: false,
            tableName: null
        };
    },
    components:{
        deleteTestModal,
        deleteUserModal
    },
    computed: {
        ...mapGetters(['loader']),
    },
    methods: {
        handleDeleteShow(url, name) {
            this.deleteUrl = url;
            this.deleteModal = true;
            this.tableName = name;
            if(this.tableName == 'Test') {
                this.$refs.deleteTestModal.handleToggleModal(url);
            }

            if(this.tableName == 'User') {
                this.$refs.deleteUserModal.handleToggleModal(url);
            }
        },
    }
};
</script>

<style scoped>
.b-sidebar-footer {
    padding: 0;
}

.position-relative {
    position: relative;
}

.icon-inside {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #ccc; /* Change the color as needed */
}

.icon-inside i {
    pointer-events: none;
}
</style>
